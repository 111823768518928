// import axios from "axios"
import axios from "../../constants/axios"

import endpoints from "../../constants/endpoints"

export const VERIFY_EMAIL = async (code, setErrors, setSuccess, setLoading) => {
  try {
    const fd = new FormData()
    fd.append("code", code)

    const res = await axios.post(
      process.env.GATSBY_BASE_URL + endpoints.VERIFY_EMAIL_ROUTE,
      fd
    )
    if (res.data.data === "false") {
      setErrors({ code: "The code you have provided is not valid." })
    }
    if (res.status === 200) {
      setSuccess(true)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    if (error.response && error.response.data) {
      setErrors(error.response.data)
    }
  }
}

export const USER_DETAIL_FETCH = async (dispatch, token) => {
  try {
    const response = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.USER_DETAIL_ROUTE
    )
    dispatch({
      type: "LOGIN",
      payload: {
        user: response.data.user,
        token: token,
      },
    })
  } catch (err) {
    dispatch({
      type: "LOGOUT",
    })
  }
}

export const RESEND_VERIFICATION_CODE = async (
  setResend,
  setResending,
  setResendAllowed
) => {
  try {
    const res = await axios.post(
      process.env.GATSBY_BASE_URL + endpoints.RESEND_VERIFICATION_EMAIL_ROUTE
    )
    if (res.status === 200) {
      setTimeout(() => {
        setResend(true)
      }, 2000)
    }
    setResending(false)
  } catch (err) {
    setResending(false)
  }
}
