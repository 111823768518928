import React, { useState, useContext, useEffect } from "react"

import { navigate } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import {
  VERIFY_EMAIL,
  RESEND_VERIFICATION_CODE,
  USER_DETAIL_FETCH,
} from "./verifyEmailAction"

import { GlobalAuthContext } from "../../context/authContext"

import Warning from "../../images/icons/wdn-warning.png"

import "./verify-email.scss"
import InputTextBox from "../input/input-text-box"
import BreadcrumbComponent from "../breadcrumb/breadcrum"

const VerifyEmail = () => {
  const [error, setError] = useState({})
  const [code, setCode] = useState("")
  const [isSuccess, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isResend, setResend] = useState(false)
  const [isResending, setResending] = useState(false)
  const [time, setTime] = useState(60)
  const [resendAllowed, setResendAllowed] = useState(false)

  const { state, dispatch } = useContext(GlobalAuthContext)

  const currentPage = { name: "Verify Email" }

  const handleCodeInputChange = email => {
    setCode(email)
  }

  const handleInputFocus = () => {
    setError({})
  }

  const handleEmailVerificationFormSubmit = e => {
    e.preventDefault()
    setError({})
    try {
      if (code !== "") {
        setLoading(true)
        VERIFY_EMAIL(code, setError, setSuccess, setLoading)
      } else {
        setError({ code: "You haven't provided any code." })
        setLoading(false)
      }
    } catch (err) {}
  }

  const handleResetClick = e => {
    e.preventDefault()
    setError({})
    if (!isResending && resendAllowed) {
      setTime(60)
      setResending(true)
      setResendAllowed(false)
      setResend(!isResend)
      RESEND_VERIFICATION_CODE(setResend, setResending, setResendAllowed)
    } else {
      setError({
        message: "Please wait for at least 1 minute before clicking resend",
      })
    }
  }

  useEffect(() => {
    if (!resendAllowed) {
      setTime(60)
      setResendAllowed(false)
    }
    try {
      if (isSuccess) {
        const token = localStorage.getItem("token")
        USER_DETAIL_FETCH(dispatch, token)
        navigate("/login")
      }
    } catch (error) {}
  }, [state.isEmailVerified, isSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BreadcrumbComponent currentPage={currentPage} />

      <section className="verify-email-page">
        <Container>
          <Row className="">
            <Col lg="6 m-auto" md="10" sm="10">
              <div className="form-container">
                <div className="form-title">
                  <h1>
                    {isSuccess
                      ? "Your Email is now verified"
                      : "Verify your email"}
                  </h1>

                  <p>
                    {isSuccess
                      ? "Thank you for verifying your email."
                      : "We have sent you an email with verification code."}
                  </p>
                </div>

                <form onSubmit={handleEmailVerificationFormSubmit}>
                  <InputTextBox
                    name="code"
                    label="Verification code"
                    onFocus={handleInputFocus}
                    onChange={handleCodeInputChange}
                    value={code}
                    class={error && error.code && `error`}
                    type="text"
                  />

                  <p className=" mb-4 ">
                    Haven't got the code?
                    <button
                      onClick={handleResetClick}
                      disabled={!resendAllowed}
                    >
                      {resendAllowed && "Resend"}
                      <span className="timer">
                        {" "}
                        {!resendAllowed && "Sent "}
                        {!resendAllowed && (
                          <Timer
                            time={time}
                            setResendAllowed={setResendAllowed}
                            setTime={setTime}
                          />
                        )}
                      </span>
                    </button>
                  </p>

                  {error.code && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {error.code}
                    </div>
                  )}

                  {error.message && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {error.message}
                    </div>
                  )}

                  <div className="form-group mb-0">
                    <input
                      type="submit"
                      className="btn-custom primary w-100 custom-border rounded"
                      disabled={isLoading}
                      value={isLoading ? `Processing` : `Verify`}
                    />
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

const Timer = ({ time, setTime, setResendAllowed }) => {
  useEffect(() => {
    if (time > 0) {
      const timer = setTimeout(() => setTime(time - 1), 1000)
      return () => {
        clearTimeout(timer)
      }
    } else {
      setResendAllowed(true)
    }
  }, [time]) // eslint-disable-line react-hooks/exhaustive-deps
  return <span>(Request after: {time}s)</span>
}

export default VerifyEmail
