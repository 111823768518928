import React, { useState, useEffect, useContext } from "react"

import { navigate } from "gatsby"

import { GlobalAuthContext } from "../context/authContext"

import SEO from "../components/seo"
import VerifyEmail from "../components/verify-email/verify-email"
import Layout from "../components/layout/layout"
import Spinner from "../components/spinner/spinner"

const VerifyEmailPage = ({ location }) => {
  const [wholePageLoading, setWholePageLoading] = useState(true)
  const { state } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (state?.isAuthenticated && !state?.isEmailVerified) {
      setWholePageLoading(false)
    } else if (!localStorage.token) {
      setWholePageLoading(false)
    } else if (state?.isAuthenticated && state?.isEmailVerified) {
      navigate("/")
    }
  }, [state?.isAuthenticated, state?.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps
  if (wholePageLoading) return <Spinner />
  return (
    <>
      <Layout
        noVerification={true}
        isAuthenticated={true}
        active="verify-email"
        location={location}
      >
        <SEO title="Verify Email" robot_no_index />
        <VerifyEmail />
      </Layout>
    </>
  )
}
export default VerifyEmailPage
